import React, { useState, useEffect } from "react";
import "./Blog.css";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Blog() {
  const navigate = useNavigate();
  const [blog, setBlog] = useState({});
  const [postData, setPostData] = useState({});
  const [loadingBlog, setLoadingBlog] = useState(false);
  const [errorBlog, setErrorBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const BlogValues = async () => {
      setLoadingBlog(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readBlogId/1`);
        setBlog(response.data);
        setLoadingBlog(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorBlog("Failed to fetch default values.");
        setLoadingBlog(false);
      }
    };
    BlogValues();
  }, []);

  useEffect(() => {
    const postValues = async () => {
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readAllPosts`);

        setPostData(response.data);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
      }
    };

    postValues();
  }, []);

  const tableData = Array.isArray(postData) ? postData : [];

  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust based on screen size for responsiveness
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div>
      <div class="blog-section">
        <div class="container">
          <h3 class="blog-section-subtitle">{blog.subtitle}</h3>
          <h3 class="blog-section-title">{blog.title}</h3>

          <div className="row" >
            <Slider {...carouselSettings} >
              {tableData.map((blog) => (
                <div key={blog.id} 
                // onClick={() => navigate(`/post/${blog.id}`)} 
                >
                  <article className="blog-single-post" style={{ height: "530px", overflow: "hidden",marginRight:"20px", marginLeft:"20px" }}>
                    <div className="blog_custom">
                      <div className="post-thumbnail" onClick={() => navigate(`/post/${blog.id}`)}>
                        <div className="featured_image_blog aspect-ratio-container">
                          <img
                            className="lazy blog_post_image img-fluid"
                            src={blog.photo_url}
                            alt={blog.title}
                          />
                          <div className="flex-icon">
                            <div className="flex-icon-inside">
                              <i className="fas fa-link"></i>
                            </div>
                          </div>
                        </div>
                        <div className="post-categories" style={{ marginLeft: "0px" }}>
                          <p>{blog.categories}</p>
                        </div>
                      </div>
                      <div className="post-details">
                        <div className="post-category-comment-date">
                          <span className="post-date">
                            <i className="far fa-clock"></i>
                            <span>{formatDate(blog?.created_at)}</span>
                          </span>
                          <span className="post-author">
                            <i className="far fa-user"></i>
                            <a href="#0">Admin</a>
                          </span>
                        </div>
                        <h3 className="post-name" onClick={() => navigate(`/post/${blog.id}`)}>
                          <a>{blog.title}</a>
                        </h3>
                        <div className="post-excerpt">
                          <p style={{ textWrap: "pretty" }}>
                            {blog.meta_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Blog;
