import React, { useState, useEffect, useRef } from "react";
import "./Footer.css";

import { ReactTyped } from "react-typed";

import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ngrokUrl } from "../../../Utility/data";
import { Link } from "react-router-dom";
import logoLeft from "../../../Images/1615631850niva4logo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer() {
  const [faviconData, setFaviconData] = useState({});

  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedInputs, setSelectedInputs] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState(null);
  const [information, setInformation] = useState({});
  const [loadinginfo, setLoadinginfo] = useState(false);
  const [errorinfo, setErrorinfo] = useState(null);
  const [websitesettings, setWebsiteSettings] = useState({
    website_title: "",
    author: "",
    contact_address: "",
    phone_whatsapp: "",

    address: "",
  });
  const [headerFooter, setHeaderFooter] = useState({
    title_contactus: " ",
    header_desc: " ",
    typed_title: "",
    typed_text: " ",
    button_text: "  ",
    button_link: "",
    subtitle: " ",
    title: " ",
    button_text_contact: " ",
    button_link_fot: " ",
    title_quicklinks: " ",
    title_sayhello: "",
    desc_foot: " ",
    desc_footer: " ",
    copyright: " ",
  });
  const [formData, setFormData] = useState({
    designType: "",
    name: "",
    company: "",
    phone: "",
    whatsapp: "",
    email: "",
    address: "",
    message: "",
    preferred_contact: "",
    file: "",
    selectedFileType: null,
  });

  const handleModalChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "radio" ? (checked ? value : prevState[name]) : value,
    }));
  };

  const fileInputRef = useRef(null);

  const emails = information.box_desc_two
    ? information.box_desc_two.split(/[,\n]+/).map((email) => email.trim())
    : [];

  useEffect(() => {
    const contactInformation = async () => {
      setLoadinginfo(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readContactUs/1`);
        setInformation(response.data);
        setLoadinginfo(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setErrorinfo("Failed to fetch default values.");
        setLoadinginfo(false);
      }
    };
    contactInformation();
  }, []);

  useEffect(() => {
    const HeaderFooterData = async () => {
      try {
        const response = await axios.get(
          `${ngrokUrl.url}/api/readHeaderFooter/3`
        );

        const data = await response.data;

        setHeaderFooter(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    HeaderFooterData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeaderFooter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputClick = (index, value) => {
    if (selectedInputs.includes(index)) {
      setSelectedInputs(selectedInputs.filter((item) => item !== index));
      setFormData((prevState) => ({
        ...prevState,
        designType: "",
      }));
    } else {
      setSelectedInputs([index]);
      setFormData((prevState) => ({
        ...prevState,
        designType: value,
      }));
    }
  };

  const selectFileType = (type) => {
    setSelectedFileType(type);
    setFormData((prevState) => ({
      ...prevState,
      selectedFileType: type,
    }));
  };

  const handleShow = () => setShowmodal(true);
  const handleClose = async () => {
    try {
      setShowmodal(false);
      setFormData({
        name: "",
        company: "",
        phone: "",
        whatsapp: "",
        email: "",
        address: "",
        message: "",
      });
    } catch (error) {
      console.error("Error closing modal:", error);
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadStatus("File Uploaded");
      setFormData((prevState) => ({
        ...prevState,
        fileName: file.name,
      }));
    }
  };

  const handleUpload = () => {
    setUploadStatus("Upload Complete");
  };

  useEffect(() => {
    function scrollToContactSection(event) {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }

    const contactButton = document.getElementById("contact-button");
    if (contactButton) {
      contactButton.addEventListener("click", scrollToContactSection);
    }

    return () => {
      if (contactButton) {
        contactButton.removeEventListener("click", scrollToContactSection);
      }
    };
  }, []);

  const handleSubmit = async () => {
    try {
      await axios.post(`${ngrokUrl.url}/api/submit-quote`, formData);
      toast.success("Form submitted successfully!");
      setFormData({
        name: "",
        company: "",
        phone: "",
        whatsapp: "",
        email: "",
        address: "",
        message: "",
      });

      setFileName("");
      setSelectedInputs([]);
      setSelectedFileType(null);
      setUploadStatus("");
    } catch (error) {
      console.error("Failed to submit form.", error);
      setError("Failed to submit form.");
      toast.success("Failed to submit form");
    }
  };

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readAllPage`);
        setPage(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchDefaultValues();
  }, []);
  const typedText = headerFooter.typed_text;
  const parseTypedText = () => {
    const cleanedText = typedText.replace(/^\[|\]$/g, "").replace(/['"]+/g, "");

    return cleanedText.split(",").map((item) => item.trim());
  };

  useEffect(() => {
    const fetchDefaultValues = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${ngrokUrl.url}/api/readLogo/1`);
        setFaviconData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch default values.", error);
        setError("Failed to fetch default values.");
        setLoading(false);
      }
    };

    fetchDefaultValues();
  }, []);
  useEffect(() => {
    const websitesettingsData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${ngrokUrl.url}/api/readSettings/3`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        const data = await response.data;

        setWebsiteSettings(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    websitesettingsData();
  }, []);

  const typedTextArray = parseTypedText(headerFooter.typed_text);

  return (
    <div
      style={{
        backgroundImage: `url("https://www.sharjeelanjum.com/demos/avon/images/contact-bg.jpg")`,
      }}
    >
      <div class="typed-section">
        <div class="container">
          <div
            class="row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="col-md-8">
              <h4 class="parent-typed-text">
                <span class="mt_typed-beforetext" style={{ color: "white" }}>
                  {headerFooter.typed_title}{" "}
                </span>
                <span class="mt_typed_text"></span>
                <span class="typed-cursor"></span>

                <ReactTyped
                  strings={typedTextArray}
                  typeSpeed={80}
                  backSpeed={80}
                  loop
                  style={{ color: "white" }}
                />
              </h4>
            </div>
            <div class="col-md-4 text-right">
              <div
                target="_self"
                class="btn btn-style1"
                onClick={handleShow}
                style={{ cursor: "pointer", marginTop: "0px" }}
              >
                <span className="contact-text">{headerFooter.button_text}</span>
              </div>
              {showmodal && (
                <>
                  <Modal
                    show={showmodal}
                    onHide={handleClose}
                    style={{ opacity: "1" }}
                    className="custom-modal"
                    centered
                  >
                    <div className="Title">
                      <div className="main_heading">
                        <h1>
                          Get A <span style={{ color: "#94618e" }}>Quote</span>{" "}
                        </h1>
                        <h3>
                          Request a quotation within a 30-minute time-frame.
                        </h3>
                      </div>

                      <div>
                        <button
                          data-bs-dismiss="modal"
                          type="button"
                          class="close-button"
                          onClick={handleClose}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                    </div>

                    <Modal.Body className="custome_text">
                      <div className="custom_header">
                        <div className="header_section">
                          <div className="header_list">
                            <input
                              type="text"
                              value="UI/UX design"
                              readOnly
                              className={
                                selectedInputs.includes(0) ? "selected" : ""
                              }
                              onClick={() =>
                                handleInputClick(0, "UI/UX design")
                              }
                            />
                          </div>
                          <div className="header_list">
                            <input
                              type="text"
                              value="Web design"
                              readOnly
                              className={
                                selectedInputs.includes(1) ? "selected" : ""
                              }
                              onClick={() => handleInputClick(1, "Web design")}
                            />
                          </div>
                          <div className="header_list">
                            <input
                              type="text"
                              value="Graphic design"
                              readOnly
                              className={
                                selectedInputs.includes(2) ? "selected" : ""
                              }
                              onClick={() =>
                                handleInputClick(2, "Graphic design")
                              }
                            />
                          </div>
                          <div className="header_list">
                            <input
                              type="text"
                              value="Design system"
                              readOnly
                              className={
                                selectedInputs.includes(3) ? "selected" : ""
                              }
                              onClick={() =>
                                handleInputClick(3, "Design system")
                              }
                            />
                          </div>
                          <div
                            className="header_list"
                            style={{ width: "75px" }}
                          >
                            <input
                              id="Other"
                              style={{ width: "75px", height: "50px" }}
                              type="text"
                              value="Other"
                              readOnly
                              className={
                                selectedInputs.includes(4) ? "selected" : ""
                              }
                              onClick={() => handleInputClick(4, "Other")}
                            />
                          </div>
                        </div>

                        <div className="fileUpload">
                          <h3 className="uploadtext ml-3">Upload file</h3>
                          <div className="file-upload">
                            <div className="fileIcon">
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileInputChange}
                              />
                              <button
                                className="plusButton"
                                onClick={handleFileUpload}
                              >
                                +
                              </button>
                            </div>
                            <p className="file-label">
                              {uploadStatus ? uploadStatus : "Click to upload"}
                            </p>

                            {!uploadStatus ? (
                              <div className="FileType">
                                <div
                                  className={`type_section ${
                                    selectedFileType === "PDF" ? "selected" : ""
                                  }`}
                                  onClick={() => selectFileType("PDF")}
                                >
                                  <h3>PDF</h3>
                                </div>
                                <div
                                  className={`type_section ${
                                    selectedFileType === "DOCX"
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => selectFileType("DOCX")}
                                >
                                  <h3>DOCX</h3>
                                </div>

                                <div
                                  className={`type_section ${
                                    selectedFileType === "PPT" ? "selected" : ""
                                  }`}
                                  onClick={() => selectFileType("PPT")}
                                >
                                  <h3>PPT</h3>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="update"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <h3>{fileName}</h3>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form_section">
                        <div className="input-section">
                          <input
                            type="text"
                            placeholder="Your name *  "
                            onChange={handleModalChange}
                            name="name"
                            value={formData.name}
                          />

                          <input
                            type="text"
                            placeholder="Company Name (Optional)"
                            onChange={handleModalChange}
                            name="company"
                            value={formData.company}
                          />
                          <input
                            type="text"
                            placeholder="Phone Number *"
                            onChange={handleModalChange}
                            name="phone"
                            value={formData.phone}
                          />
                          <input
                            type="text"
                            placeholder="Whatsapp Number(optional)"
                            onChange={handleModalChange}
                            name="whatsapp"
                            value={formData.whatsapp}
                          />
                          <input
                            type="email"
                            placeholder="Email Id *"
                            onChange={handleModalChange}
                            name="email"
                            value={formData.email}
                          />
                          <input
                            type="text"
                            placeholder="Address"
                            onChange={handleModalChange}
                            name="address"
                            value={formData.address}
                          />
                          <input
                            type="text"
                            placeholder="Your message"
                            value={formData.message}
                            onChange={handleModalChange}
                            name="message"
                          />

                          <div className="radio-group">
                            <p className="radio-label">
                              Preferred Contact Method *
                            </p>
                            <div className="radio-options">
                              <input
                                type="radio"
                                name="preferred_contact"
                                value="email"
                                checked={formData.preferred_contact === "email"}
                                onChange={handleModalChange}
                              />
                              <label className="radio-inline">Email</label>
                              <input
                                type="radio"
                                name="preferred_contact"
                                value="phone"
                                checked={formData.preferred_contact === "phone"}
                                onChange={handleModalChange}
                              />
                              <label className="radio-inline">
                                Phone Number
                              </label>
                              <input
                                type="radio"
                                name="preferred_contact"
                                value="whatsapp"
                                checked={
                                  formData.preferred_contact === "whatsapp"
                                }
                                onChange={handleModalChange}
                              />
                              <label className="radio-inline">WhatsApp</label>
                            </div>
                          </div>
                        </div>
                        <button className="submitBtn" onClick={handleSubmit}>
                          <span style={{ paddingRight: "4px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-send"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                            </svg>
                          </span>
                          Send Message
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <ToastContainer autoClose={2000} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <footer class="footer-section ">
        <div class="footer-wrapper">
          <div class="row align-items-end">
            <div class="col-lg-6">
              <div
                class="footer-left"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "395px",
                }}
              >
                <div style={{ paddingBottom: "60px" }}>
                  <a className="navbar-brand" title="Niva CMS Theme">
                    <Link to="/">
                      <img className="footer_Logo" src={logoLeft} alt="" />
                    </Link>
                  </a>
                </div>
                <div class="inner">
                  <span>{headerFooter.subtitle}</span>
                  <h4>{headerFooter.title_contactus}</h4>
                  <span>
                    <a id="contact-button" href="/contact#contact-section">
                      <button
                        className="btn btn-style2 footerBtn"
                        style={{ backgroundColor: "#49274a" }}
                      >
                        <span>{headerFooter.button_text_contact}</span>
                      </button>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="footer-right">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="footer-widget">
                      <div class="footer-widget widget_nav_menu">
                        <h4 class="title">Quick Links</h4>
                        <span class="niva-animate-border"></span>
                        <div class="menu-quick-link-container">
                          <ul className="menu">
                            {Array.isArray(page) &&
                              page.map((item) => (
                                <li
                                  className="menu-item-footer"
                                  key={item.id}
                                  style={{}}
                                >
                                  <a
                                    className="footerText"
                                    href={`/${item.link}`}
                                    style={{ color: "white" }}
                                  >
                                    {item.title}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="footer-widget">
                      <div class="widget widget_custom_html">
                        <h4 class="title">Say Hello</h4>
                        <span class="niva-animate-border"></span>
                        <div class="custom-html-widget">
                          <ul class="ft-link">
                            <li className="menu-item-footer1">
                              {emails.map((email, index) => (
                                <p key={index}>
                                  <a href={`mailto:${email}`}>{email}</a>
                                </p>
                              ))}
                            </li>
                          </ul>
                          <div class="social-share-inner">
                            <ul class="social-share">
                              <li>
                                <a
                                  href="https://www.instagram.com/yrpitsolutions?igsh=MW95ZzV1YW1mdWN3Yg=="
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i class="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://in.linkedin.com/company/ypritsolutions"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <i class="fa-brands fa-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="copyright-text">
                      <p>
                        <a href="https://yrpitsolutions.com/">
                          {headerFooter.copyright}{" "}
                          <span className="authorText">
                            {websitesettings.author}
                          </span>
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
